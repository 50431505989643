<template>
    <v-app>
        <v-main>
            <v-container fluid class="fill-height pa-0">
                <v-row class="fill-height">
                    <v-col cols=12 md=7 class="navigation-part">
                        <router-view v-slot="{ Component }">
                            <transition name="fade" mode="out-in">
                                <component :is="Component" />
                            </transition>
                        </router-view>
                    </v-col>
                    <v-col cols="5" class="d-none d-md-block side-info-panel-background">
                        <info-panel />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
	</v-app>
</template>

<script>

import InfoPanel from '@/components/info-panel/InfoPanel.vue';

export default {

    components: {
        InfoPanel,
    },

}

</script>

<style>

#app {
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.navigation-part {
    max-height: 100vh;
    overflow-y: auto;
    margin-bottom: 120px;
}

.side-info-panel-background {
    background-image: url(./assets/panel-info-background.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
}

.side-info-panel-background::after {
    content: '';
    display: block;
    position: fixed;
    width: 41.6%;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #272954;
    opacity: 0.8;
}

</style>
