<template>
    <div>
        <v-row v-if="checkActionsProducts != 0 && hasProductsMultipleTypeIds" class="mt-7">
            <v-col cols="auto" class="py-0">
            <v-radio-group v-model="formationType" inline>
                <v-radio label="Formules et prépas à l'année" value="formule" class="mr-3"></v-radio>
                <v-radio label="Stages à la carte" value="stage"></v-radio>
            </v-radio-group>
            </v-col>
        </v-row>
        <v-row v-if="formationType == 'formule' && hasFormuleMultipleType" class="mt-0">
            <v-col cols="auto" class="d-flex align-center pl-5 pr-0"><span class="font-weight-bold">Type de formule :</span></v-col>
            <v-col v-if="formuleHasType(typeFormuleTwoStage)" cols="auto" class="pl-0 pr-1 py-0">
                <v-checkbox v-model="twoActions" label="2 stages" hide-details></v-checkbox>
            </v-col>
            <v-col v-if="formuleHasType(typeFormuleThreeStage)" cols="auto" class="px-l pr-1 py-0">
                <v-checkbox v-model="threeActions" label="3 stages" hide-details></v-checkbox>
            </v-col>
            <v-col v-if="formuleHasType(typeFormuleFourStage)" cols="auto" class="px-l pr-1 py-0">
                <v-checkbox v-model="fourActions" label="4 stages" hide-details></v-checkbox>
            </v-col>
            <v-col v-if="formuleHasType(typeFormuleWeekend)" cols="auto" class="px-l pr-1 py-0">
                <v-checkbox v-model="weekendActions" label="Week-ends" hide-details></v-checkbox>
            </v-col>
            <v-col v-if="formuleHasType(typeFormuleOther)" cols="auto" class="px-l pr-1 py-0">
                <v-checkbox v-model="otherActions" label="Autre" hide-details></v-checkbox>
            </v-col>
        </v-row>
        <div class="mt-10">
            <module-inscription-card 
                v-for="(product, key) in filteredProducts" 
                :key="key" class="my-4" 
                :product="product" 
                :periodCaptions="periodCaptions"
                :isLimitCart="isLimitCart"
                @add-to-cart="AddToCart"
            />
        </div>
        <v-row v-if="checkActionsProducts == 0" justify=center class="px-3 mt-13">
            <span class="font-weight-medium">
                Aucune formation programmée correspondant à ces critères.<br>
                Vous pouvez nous joindre au <span class="font-weight-bold phone-number"> 01 42 05 41 36 </span> pour plus de précisions.
            </span>
        </v-row>
        <v-row class="d-md-none px-3 mt-6">
            <router-link to="summary">
                <v-btn class="font-weight-normal d-lg-none" color="primary">
                    R<span class="text-lowercase">écapitulatif</span>
                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                </v-btn>
            </router-link>
        </v-row>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

import ModuleInscriptionCard from '@/components/module-inscription/ModuleInscriptionCard.vue';

export default {

    components: {
        ModuleInscriptionCard
    },

    props: {
        products: {
            type: Array,
            required: true
        },
        selectedChild: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            formationType: 'stage',
            twoActions: false,
            threeActions: false,
            fourActions: false,
            weekendActions: false,
            otherActions: false,
            filteredProducts: null,
            periodCaptions: [],
            typeCourseUnit: 2900019,
            typeCourseFormule: 2900012,
            typeFormuleOther: 7000,
            typeFormuleThreeStage: 7001,
            typeFormuleFourStage: 7002,
            typeFormuleWeekend: 7003,
            typeFormuleTwoStage: 7004,
            typeFormulePlusStage: 80001
        };
    },

    computed: {

        ...mapGetters([
            'getlimitCart',
            'getSelectedChild',
            'getSelectedClass',
            'getUser'
        ]),

        checkActionsProducts() {
            if(this.products.length > 0) {
                return this.products.filter(product => {
                    return product.actions.length > 0
                }).length
            } else {
                return 0
            }
        },

        hasProductsMultipleTypeIds() {
            const productArray = Object.values(this.products);

            const hasValidFormuleProduct = productArray.some(product =>
                product.type_id == this.typeCourseFormule &&
                product.formule_type != this.typeFormulePlusStage &&
                product.actions.some(action => action.type_id != this.typeCourseUnit && !action.invisible_inscription_form)
            );

            if (!hasValidFormuleProduct) {
                return false;
            }

            const hasOtherValidProduct = productArray.some(product =>
                (product.type_id != this.typeCourseFormule || (product.type_id == this.typeCourseFormule && product.formule_type == this.typeFormulePlusStage)) &&
                product.actions.some(action => action.type_id != this.typeCourseUnit && !action.invisible_inscription_form)
            );

            return hasOtherValidProduct;
        },

        hasFormuleMultipleType() {
            const productArray = Object.values(this.products);
            const formuleType = new Set();

            for (const product of productArray) {
                if (product.type_id == this.typeCourseFormule) {
                    const hasValidAction = product.actions.some(action => action.type_id != this.typeCourseUnit && !action.invisible_inscription_form);
                    if(hasValidAction) {
                        formuleType.add(product.formule_type);
                    }
                }
            }

            return formuleType.size >= 2;
        },

        isLimitCart() {
            if(this.getSelectedClass == '100005' && this.getlimitCart[this.getSelectedChild] > 2) {
                return true
            } else if ((this.getSelectedClass == '100006' || this.getSelectedClass == '100019') && this.getlimitCart[this.getSelectedChild] > 1) {
                return true
            } else {
                return false
            }
        }

    },

    methods: {

        AddToCart(session) {
            const datas = {
                'session': session,
                'user':  this.getUser.children[this.getSelectedChild]
            }
            this.$store.dispatch('addToCart', datas)
        },

        filterProducts() {
            if(!this.hasProductsMultipleTypeIds) {
                const products = this.products
                this.filteredProducts = this.sortFilterProductsByPrice(products)
            }
            else if(this.formationType == "formule") {
                const formuleProducts = this.products.filter(product => {
                    return product.type_id === this.typeCourseFormule
                })
                this.filteredProducts = this.filterProductTypeFormule(formuleProducts)
            } 
            else if(this.formationType == "stage") {
                const products = this.products.filter(product => {
                     return (product.type_id !== this.typeCourseFormule) || 
                        (product.type_id == this.typeCourseFormule && product.formule_type == this.typeFormulePlusStage);
                })
                this.filteredProducts = this.sortFilterProductsByPrice(products)
            }
            else {
                this.filteredProducts = this.products
            }

            this.sortFilterProducts()
        },

        filterProductTypeFormule(formuleProducts) {
            return formuleProducts.filter(product => {
                return (
                    (this.twoActions && product.formule_type == this.typeFormuleTwoStage) ||
                    (this.threeActions && product.formule_type == this.typeFormuleThreeStage) ||
                    (this.fourActions && product.formule_type == this.typeFormuleFourStage) ||
                    (this.weekendActions && product.formule_type == this.typeFormuleWeekend) ||
                    (this.otherActions && product.formule_type == this.typeFormuleOther) ||
                    (!this.twoActions && !this.threeActions && !this.fourActions && !this.weekendActions 
                    && !this.otherActions)
                )
            })
        },

        formuleHasType(formuleType) {
            const productArray = Object.values(this.products);
            for(const product of productArray) {
                if(product.type_id == this.typeCourseFormule && formuleType == product.formule_type) {
                    const hasValidAction = product.actions.some(action => action.type_id !== this.typeCourseUnit && !action.invisible_inscription_form);
                    if(hasValidAction) {
                        return true;
                    }
                }
            }
            return false
        },

        getActionPeriods() {
            this.$store.dispatch('getActionPeriods')
            .then(response => {
                this.periodCaptions = response;
            })
        },

        sortFilterProductsByPrice(products) {
            return products.sort((a, b) => {
                    const getTarif = product => {
                        const auto1Price = product.prices.find(p => p.typeTarif === 'AUTO1');
                        return auto1Price ? auto1Price.amount_ttc : product.prices.find(p => p.typeTarif === '1MAT')?.amount_ttc || 1;
                    };

                    const tarifA = getTarif(a);
                    const tarifB = getTarif(b);

                    return tarifB - tarifA;
                });
        },
        
        sortFilterProducts() {
            this.filteredProducts.sort((a, b) => {
                const aFirstPart = parseInt(a.internal_reference.split('-')[0]);
                const bFirstPart = parseInt(b.internal_reference.split('-')[0]);
                if (Number.isNaN(aFirstPart) || Number.isNaN(bFirstPart)) {
                    if (Number.isNaN(aFirstPart)) return 1;
                    if (Number.isNaN(bFirstPart)) return -1;
                }
                return aFirstPart - bFirstPart;
            });
        }

    },

    created() {
        if(this.products.length > 0) {
            this.getActionPeriods()
            this.filterProducts()
        }
    },

    watch: {

        products(value) {
            if(value.length > 0) {
                this.filterProducts();
            } else {
                this.filteredProducts = null
            }
        },

        formationType(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.filterProducts();
            }
        },

        twoActions(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.filterProducts();
            }
        },

        threeActions(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.filterProducts();
            }
        },

        fourActions(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.filterProducts();
            }
        },

        weekendActions(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.filterProducts();
            }
        },

        otherActions(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.filterProducts();
            }
        },
    },
};

</script>

<style scoped>

.phone-number {
    letter-spacing: -1px;
}

</style>