<template>
    <div>
        <v-form v-if="!createUserLoading && !createChildLoading" ref="childForm">
            <div class="text-start text-primary font-weight-bold text-h6 mt-10 mb-7">Élève</div>
            <v-row>
                <v-col cols="12" sm="4" xl="2" class="py-2">
                    <select-field v-model="child.gender" label="Civilité" :items="['M.', 'Mlle']" :rules="genderRules"></select-field>
                </v-col>
                <v-col cols="12" sm="8" xl="5" class="py-2">
                    <text-field v-model="child.firstname" label="Prénom" :rules="firstNameRules"></text-field>
                </v-col>
                <v-col cols="12" xl="5" class="py-2">
                    <text-field v-model="child.lastname" label="Nom" :rules="lastNameRules"></text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-2">
                    <text-field-phone v-model="child.phone" label="Téléphone" :rules="phoneRules"></text-field-phone>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-2">
                    <text-field v-model="child.email" label="Email (optionnel)" :rules="emailRules"></text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-2">
                    <v-autocomplete
                        v-model="child.highSchool" 
                        label="Établissement scolaire" 
                        placeholder="Tapez les premières lettres"
                        :items="highSchools"
                        item-text="caption"
                        :returnObject="true"
                        no-data-text="Aucun résultat trouvé"
                        variant="outlined"
                        @input="handleSearchChange($event)"
                        :custom-filter="compareSearchText"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-2">
                    <select-field-with-title 
                        v-model="child.class" 
                        label="Classe à la rentrée 2024" 
                        :items="classes"
                        :itemTitle="'label'"
                        :rules="classRules"
                        :returnObject="true"
                        @update:modelValue="getGenericClasses($event.id_ammon)"
                    ></select-field-with-title>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm=6 xl=4 class="py-2" v-for="(speciality, key) in specialties" :key="key">
                    <select-field-with-title
                        v-model="child.specialties[key]" 
                        :label="getoptionName + ' ' + (key+1)"
                        :items="filterSpecialties(speciality)"
                        :itemTitle="'caption'"
                        :rules="specialtiesRules"
                    ></select-field-with-title>
                </v-col>
                <v-col v-if="child.class && loading" class="pt-0 mb-3">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-2">
                    <select-field-with-title 
                        v-model="child.level" 
                        label="Niveau de l'élève (recommandé)" 
                        :items="levelOptions"
                        :itemTitle="'libelle'"
                    ></select-field-with-title>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-2">
                    <text-area v-model="child.observation" label="Observations (recommandé)"></text-area>
                </v-col>
            </v-row>

            <v-row v-if="!addNewChild" class="my-1">
                <v-col cols="12" class="d-flex justify-start">
                    <div class="d-none d-sm-block w-100 mt-2">
                        <v-row justify="space-between" class="px-3">
                            <v-btn @click="backToEditUser" color="grey-lighten-4" class="font-weight-bold mb-2">
                                R<span class="text-lowercase">etour</span>
                                <v-icon icon="mdi-arrow-left" class="ml-1"></v-icon>
                            </v-btn>
                            <v-btn @click="validateAddChildForm" color="primary" class="font-weight-bold">
                                V<span class="text-lowercase">alider et choisir une préparation</span>
                                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                            </v-btn>
                        </v-row>
                    </div>
                    
                    <v-row class="px-3 mt-0 d-sm-none">
                        <v-btn @click="backToEditUser" color="grey-lighten-4" class="font-weight-bold w-100 mb-2">
                            R<span class="text-lowercase">etour</span>
                            <v-icon icon="mdi-arrow-left" class="ml-1"></v-icon>
                        </v-btn>
                        <v-btn @click="validateAddChildForm" color="primary" class="font-weight-bold w-100">
                            V<span class="text-lowercase">alider</span>
                            <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>

            <v-row v-else>
                <v-col cols="12" class="d-flex justify-start">
                    <div class="d-none d-sm-block w-100 mt-2">
                        <v-row justify="space-between" class="px-3">
                            <v-btn @click="backToInscritpion" color="grey-lighten-4" class="font-weight-bold mb-2">
                                R<span class="text-lowercase">etour</span>
                                <v-icon icon="mdi-arrow-left" class="ml-1"></v-icon>
                            </v-btn>
                            <v-btn @click="validateAddNewChildForm" color="primary" class="font-weight-bold">
                                V<span class="text-lowercase">alider et choisir une préparation</span>
                                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                            </v-btn>
                        </v-row>
                    </div>
                    
                    <v-row class="px-3 mt-0 d-sm-none">
                        <v-btn @click="backToInscritpion" color="grey-lighten-4" class="font-weight-bold w-100 mb-2">
                            R<span class="text-lowercase">etour</span>
                            <v-icon icon="mdi-arrow-left" class="ml-1"></v-icon>
                        </v-btn>
                        <v-btn @click="validateAddNewChildForm" color="primary" class="font-weight-bold w-100">
                            V<span class="text-lowercase">alider</span>
                            <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-row v-if="createUserLoading" justify=center class="flex-column font-weight-bold mt-12">
            <v-col class="pb-0">
                Bienvenue à Cours Thalès ! <br>
                Nous créons votre compte, cette opération peut prendre quelques secondes.
            </v-col>
            <v-col class="d-flex justify-center">
                <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2 ml-1"></div>
                    <div class="bounce3 ml-1"></div>
                </div>
            </v-col>
        </v-row>

        <v-row v-if="createChildLoading" justify=center class="flex-column font-weight-bold mt-12">
            <v-col class="pb-0">
                Nous créons le nouvel élève, cette opération peut prendre quelques secondes.
            </v-col>
            <v-col class="d-flex justify-center">
                <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2 ml-1"></div>
                    <div class="bounce3 ml-1"></div>
                </div>
            </v-col>
        </v-row>

        <v-dialog v-model="showMailError" max-width="600">
            <v-card>
                <v-card-text>
                    Il est recommandé d’indiquer le mail de l’élève ET celui du responsable (ils doivent être différents).<br>
                    Le mail de l’élève pourra être renseigné plus tard.
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" block @click="showMailError = false">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>

</template>

<script>

import moment from 'moment';
import { mapGetters } from 'vuex';

import SelectField from '@/components/form-base/SelectFieldBase.vue';
import SelectFieldWithTitle from '@/components/form-base/SelectFieldWithTitleBase.vue';
import TextField from '@/components/form-base/TextFieldBase.vue';
import TextFieldPhone from '@/components/form-base/TextFieldPhoneBase.vue';
import TextArea from '@/components/form-base/TextAreaBase.vue';

export default {

    components: {
        SelectField,
        SelectFieldWithTitle,
        TextField,
        TextFieldPhone,
        TextArea
    },

    data() {
        return {
            addNewChild : false,
            createChildLoading: false,
            createUserLoading: false,
            searchInput: '',
            loading: true,
            showMailError: false,
            child: {
                gender: null,
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                level: null,
                observation: '',
                class: null,
                highSchoolCode: null,
                genericClass: '',
                specialties: [],
            },
            levelOptions: [
                {'libelle': 'Excellent', 'value': '1'},
                {'libelle': 'Bon', 'value': '2'},
                {'libelle': 'Moyen', 'value': '3'},
                {'libelle': 'Fragile', 'value': '4'},
                {'libelle': 'Indéfini', 'value': '9'}
            ],
            classes: [],
            highSchools: [],
            specialties: [],
            genderRules: [ 
                v => !!v || "La civilité est requise",
            ],
            classRules: [ 
                v => !!v || "La classe est requise",
            ],
            emailRules: [ 
                v => !v || /.+@.+\..+/.test(v) || 'Format invalide'
            ],
            firstNameRules: [ 
                v => !!v || "Le prénom est requis",
            ],
            lastNameRules: [ 
                v => !!v || "Le nom est requis",
            ],
            phoneRules: [
                v => !!v || "Le numéro de téléphone est requis",
            ],
            specialtiesRules: [ 
                v => !!v || "La spécialité est requise",
            ],
            optionNames: {
                'Spécialité': [1400248, 1400249],
                'Filiaire': [1400250, 1400251],
                'Option': [1400252, 1400253]
            }
        };
    },

    computed : {

        ...mapGetters([
            'getUser',
        ]),

        getoptionName() {
            for (const [key, value] of Object.entries(this.optionNames)) {
                if (value.includes(this.child.class.id_ammon)) {
                    return key;
                }
            }
            return 'Spécialité'
        }

    },

    methods: {

        backToEditUser() {
            this.$router.push({name: 'UserCreation'});
        },

        backToInscritpion() {
            this.$router.push({name: 'ModuleInscription'});
        },

        compareSearchText(itemText, queryText) {
            const normalizedItemText = this.removeAccents(itemText.toLowerCase());
            const normalizedQueryText = this.removeAccents(queryText.toLowerCase());
            return normalizedItemText.includes(normalizedQueryText);
        },

        filterSpecialties(specialties) {
            return specialties.filter(speciality => {
                return !this.child.specialties.includes(speciality.caption)
            })
        },

        getClassesConstants() {
            this.$store.dispatch('getClassesConstants')
            .then(response => {
                this.classes = response

            })
            .catch(error => console.log(error));
        },

        getGenericClasses(value) {
            this.child.specialties = []
            this.$store.dispatch('getGenericClasses', value)
            .then(response => {
                this.child.genericClass = response;
                this.getSpecialties(value);
            })
            .catch(error => console.log(error));
        },

        getHighSchools(search) {
            this.$store.dispatch('getHighSchools', search)
            .then(response => {
                this.highSchools = response.slice(0, 20).map(item => {
                    return {title: item.name, code: item.code}
                });
            })
            .catch(error => console.log(error));
        },

        getSpecialties(classId) {
            this.loading = true
            this.$store.dispatch('getSpecialties', classId)
            .then(specialties => {
                this.specialties = specialties;
                this.loading = false
            })
            .catch(error => console.log(error));
        },

        getSpecialtiesCode() {
            this.child.specialties.map((speciality, index) => {
                this.specialties[index].map(element => {
                    if(element.caption == speciality) {
                        this.child.specialties[index] = element.code
                    }
                })
            })
        },

        getStudentYear() {
            var currentYear = null
            var currentMonth = moment().month();
            if (currentMonth >= 5) {
                currentYear = moment().year();
            } else { 
                currentYear = moment().subtract(1, 'year').year();
            }
            return currentYear
        },

        handleSearchChange(value) {
            this.getHighSchools(value.target.value)
        },

        removeAccents(text) {
            return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        },

        async validateAddChildForm () {
            const { valid } = await this.$refs.childForm.validate()

            if(valid) {
                if (this.child.email == this.getUser.email) {
                    this.showMailError = true;
                    this.child.email = '';
                } else {
                    this.createUserLoading = true
                    this.getSpecialtiesCode()
                    this.child.class.id = this.child.class.id_ammon
                    this.$store.dispatch('createUser', this.child)
                    .then(response => {
                        this.$store.dispatch('getUser', response).
                        then(() => {
                            this.$router.push({
                                name: 'ModuleInscription',
                                query: { isFirstTime: true }
                            });
                            this.createUserLoading = false
                        })
                    });
                }
            }
        },

        async validateAddNewChildForm () {
            const { valid } = await this.$refs.childForm.validate()

            if(valid) {
                if (this.child.email == this.getUser.email) {
                    this.showMailError = true;
                    this.child.email = '';
                } else {
                    this.createChildLoading = true
                    this.getSpecialtiesCode()
                    this.child.class.id = this.child.class.id_ammon
                    this.$store.dispatch('createChild', this.child)
                    .then(response => {
                        this.$store.dispatch('getUser', response).
                        then(() => {
                            this.$router.push({
                                name: 'ModuleInscription',
                            });
                            this.createChildLoading = false
                        })
                    });
                }
            }
        },

    },

    created() {
        this.getClassesConstants()
        this.getHighSchools()
        this.addNewChild = this.$route.query.action === 'add-new-child' ? true : false
    },

};

</script>

<style scoped>

.spinner {
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: #272954;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

</style>