<template>
    <v-card class="rounded-xl pa-7" flat color="#F6F6F9">
        <v-card-title class="mb-5">
            <div class="d-flex align-center">
                <div class="font-weight-bold title">
                    {{ session.session.product.name }}
                </div>
                <v-icon @click="removeFromCart()" icon="mdi-trash-can-outline" class="ml-3" size="x-small" color="grey-lighten-1"></v-icon>
            </div>
        </v-card-title>
        <v-card-text class="text-start">
            <v-row>
                <v-col v-if="getProductSubtitleDescription(session.session.product)" class="pt-0">
                    <span class="font-weight-bold subtitle subtitle-description">{{ getProductSubtitleDescription(session.session.product) }}</span>
                </v-col>
                <v-col v-if="session.session.type_id == typeCourseFormule && getFormationCaption(session.session.product.rhythm)" cols=12 class="py-0">
                    <span class="font-weight-medium">Formation : <b>{{ getFormationCaption(session.session.product.rhythm) }}</b></span>
                </v-col>
                <v-col v-if="session.session.type_id != typeCourseFormule && getPeriodCaption(session.session.period)" cols=12 class="py-0">
                    <span class="font-weight-medium">Session : <b>{{ getPeriodCaption(session.session.period) }}</b></span>
                </v-col>
                <v-col cols=12 class="py-0">
                    <span class="font-weight-medium session-infos">Du {{ formatStartDate(session.session.start_date) }} au {{ formatStartDate(session.session.end_date) }}</span>
                </v-col>
                <v-col cols=12 class="py-0">
                    <span v-if="session.session.location" class="font-weight-medium session-infos">{{ session.session.location.description }} ({{ session.session.location.city }} {{ session.session.location.postcode }})</span>
                </v-col>
                <v-col v-if="session.session.type_id == typeCourseFormule" cols=12 class="py-0 mt-2">
                    <span class="font-weight-medium "><b>Info pratique :</b> Le choix des dates et lieux de stages se fera après l’inscription.</span>
                </v-col>
                <v-col v-if="session.session.type_id != typeCourseFormule" cols=12 class="py-0 mt-2">
                    <span class="font-weight-medium "><b>Info pratique :</b> L'emploi du temps sera accessible sur votre espace personnel.</span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import moment from 'moment';

export default {
    
    props: {
        session: Object,
        index: Number
    },

     data() {
        return {
            captionsFormations: [],
            captionsPeriods: [],
            descriptionProductSubtitleCode: 1800767,
            typeCourseFormule: 1900341
        }
    },

    methods: {

        getFormations() {
            this.$store.dispatch('getConstantByType', 1900080)
            .then(response => {
                this.captionsFormations = response;
            })
        },

        getFormationCaption(formationCode) {
            const caption = this.captionsFormations.find(discipline => discipline.code === formationCode)
            return caption ? caption.caption : ''
        },

        getPeriods() {
            this.$store.dispatch('getActionPeriods')
            .then(response => {
                this.captionsPeriods = response;
            })
        },

        getPeriodCaption(PeriodCode) {
            const caption = this.captionsPeriods.find(period => period.code === PeriodCode)
            return caption ? caption.caption : ''
        },

        getProductSubtitleDescription(product) {
            const description = product.descriptions.find(description => description.category_id == this.descriptionProductSubtitleCode);
            return description ? description.short_description : '';
        },

        formatStartDate(startDate) {
            return moment(startDate).format('DD/MM/YYYY');
        },

        formatEndDate(endDate) {
            return moment(endDate).format('DD/MM/YYYY');
        },

        removeFromCart() {
            this.$store.dispatch('removeFromCart', this.index);
        }
    },

    created() {
        this.getFormations()
        this.getPeriods()
    }
}

</script>

<style scoped>

.subtitle-description{
    color: #10757a;
}

.title {
    font-size: 20px;
    white-space: normal;
    text-align: start;
}

</style>